/** @jsx jsx */
import { CustomSlide } from "gatsby-theme-hero-carousel";
import type { HeroImageProps } from "gatsby-theme-hero-image";
import { FC, memo } from "react";
import { withProtocol } from "shared/helpers/withProtocol";
import { jsx } from "theme-ui";

const HeroImage: FC<HeroImageProps> = ({
  title,
  subtitle,
  buttonLabel,
  buttonLink,
  imageUrl,
}) => {
  return (
    <CustomSlide
      slide={{
        image: {
          src: imageUrl ? withProtocol(imageUrl) : null,
          alt: title,
        },
        caption: title,
        subCaption: subtitle,
        button: {
          label: buttonLabel,
          link: buttonLink,
        },
      }}
    />
  );
};

export default memo(HeroImage);
