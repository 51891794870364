/** @jsx jsx */
import { graphql } from "gatsby";
import { HeroImage } from "gatsby-theme-hero-image";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetHeroImage" }>;
}

const HeroImageWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
}) => {
  return widget.heroImageShape?.imageUrl ? (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={"FULL"}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
      contained={false}
    >
      <HeroImage
        title={widget.heroImageShape.title}
        subtitle={widget.heroImageShape.subtitle}
        buttonLink={widget.heroImageShape.buttonLink}
        buttonLabel={widget.heroImageShape.buttonLabel}
        imageUrl={widget.heroImageShape.imageUrl}
      />
    </WidgetWrapper>
  ) : null;
};

export const query = graphql`
  fragment WidgetHeroImage on WidgetHeroImage {
    id
    __typename
    heroImageShape {
      title
      subtitle
      buttonLabel
      buttonLink
      imageUrl
    }
  }
`;

export default memo(HeroImageWidget);
